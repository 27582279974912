import { IClaims } from "src/defs";
import { CacheKeys } from "src/constants/cache-keys";
import cacheService, { CacheService } from "./cache.service";
import {
  ClaimsTableConfig,
  ClaimsTableConfigReset,
} from "src/configs/components-config";
import { _ } from "src/shared/libs";
import { sentenceToCamelCase } from "src/utils/transformers";
import {
  CachedClaimRecord,
  CanvasTypes,
  ClaimDetails,
  ClaimIDsCache,
  UserProfile,
} from "./types";
import { InsuranceClaimRecord } from "../components/shared/claimTable/types";
import { queryService, userService } from "./index";
import {
  claimAnalyticsNormalizer,
  getClaimLineItem,
  getPageVersionFromPath,
} from "../components/shared/helper";
import { client } from "../App";
import { gql } from "apollo-boost";
import { refreshMyProfile } from "../shared/helper";

export class ClaimsTableService {
  private _config: IClaims.IClaimsQueryConfig = this.getDefaultConfig();
  private _selectedRowId: string | undefined = undefined;
  private _visibleColsV1: IClaims.IVisibleColsDictionary = [] as any;
  private _visibleColsV2: IClaims.IVisibleColsDictionary = [] as any;

  constructor(private _cacheService: InstanceType<typeof CacheService>) {
    const config = _cacheService.getItemsSync(CacheKeys.ClaimsTableConfig);
    if (config) this.setConfig(config, false);
  }

  processColumns(clientConfig: any, version = 1) {
    let mapped: Array<IClaims.IVisibleColsDictionary> | Array<any>;
    const keyName = version > 1 ? "claimLevelHeadersConfig" : "headersConfig";
    if (clientConfig && clientConfig[keyName]) {
      mapped = clientConfig[keyName];
    } else {
      // Each time when new client logged in, this will execute and show header from clientHeader
      mapped = (clientConfig?.clientHeaders || []).map((colName: string) => ({
        colName,
        checked: true,
        displayName: colName,
      }));
    }

    const entities = _.keyBy(mapped, (o: any) => {
      return sentenceToCamelCase(o.colName);
    });
    if (entities["overjetReviewResults"]) {
      entities["overjetReviewResults"].colName = "overjetReviewResult";
    }
    return _.keyBy(mapped, (o: any) => {
      return sentenceToCamelCase(o.colName);
    });
  }

  setVisibleColumnsFromCache() {
    if (Object.keys(this._visibleColsV1).length) return this._visibleColsV1;
    if (Object.keys(this._visibleColsV2).length) return this._visibleColsV2;

    const userProfile: UserProfile = this._cacheService.getItemsSync(
      CacheKeys.UserProfile
    );
    if (!userProfile) return [];

    this._visibleColsV1 = this.processColumns(userProfile.clientConfig);
    this._visibleColsV2 = this.processColumns(userProfile.clientConfig, 2);
  }

  getDefaultConfig(version = 1) {
    let sort;
    if (version === 1) {
      sort =
        ClaimsTableConfigReset.defaultSort as IClaims.IClaimsQueryConfig["sort"];
    } else {
      sort =
        ClaimsTableConfigReset.customSort as IClaims.IClaimsQueryConfig["sort"];
    }
    return {
      sort,
      search: ClaimsTableConfig.default.search,
      procedureType: [ClaimsTableConfig.default.procedureType],
      startDate: ClaimsTableConfig.default.startDate,
      endDate: ClaimsTableConfig.default.endDate,
      filter: ClaimsTableConfig.default.filter as any,
      systemFilter: ClaimsTableConfig.default.systemFilter as any,
      page: {
        offset: ClaimsTableConfig.default.pageOffset,
        size: ClaimsTableConfig.default.pageSize,
      },
    };
  }

  getResetDefaultConfig(version = 1) {
    let sort;
    if (version === 1) {
      sort =
        ClaimsTableConfigReset.defaultSort as IClaims.IClaimsQueryConfig["sort"];
    } else {
      sort =
        ClaimsTableConfigReset.customSort as IClaims.IClaimsQueryConfig["sort"];
    }
    return {
      sort,
      startDate: ClaimsTableConfigReset.default.startDate,
      endDate: ClaimsTableConfigReset.default.endDate,
      filter: ClaimsTableConfigReset.default.filter as any,
      systemFilter: ClaimsTableConfigReset.default.systemFilter as any,
      search: ClaimsTableConfigReset.default.search,
      procedureType: ClaimsTableConfigReset.default.procedureType,
      page: {
        offset: ClaimsTableConfigReset.default.pageOffset,
        size: ClaimsTableConfigReset.default.pageSize,
      },
    };
  }
  setConfig(config: IClaims.IClaimsQueryConfig, persistant = false) {
    this._config = config;
    if (persistant) {
      this._cacheService.setItemSync(CacheKeys.ClaimsTableConfig, config);
    }
  }
  getConfig(version = 1) {
    return this.getDefaultConfig(version);
  }
  getSelectedRowId() {
    return this._selectedRowId;
  }
  setSelectedRowId(id: string) {
    const rowSelect = (id: string) =>
      document.querySelectorAll(`table tr[data-row-key="${id}"]`);
    if (this._selectedRowId)
      rowSelect(this._selectedRowId).forEach((ele) =>
        ele.removeAttribute("id")
      );

    rowSelect(id).forEach((ele) => ele.setAttribute("id", "ia-selected-row"));
    this._selectedRowId = id;
  }
  crawlToSelectedRow() {
    const rowId = this.getSelectedRowId();
    const element = document.querySelectorAll(
      `table tr[data-row-key="${rowId}"]`
    );
    if (!element) return;

    element.forEach((e) => {
      e.setAttribute("id", "ia-selected-row");
      e.scrollIntoView({ block: "center" });
    });
  }

  setVisibleCols(cols: IClaims.IVisibleColsDictionary) {
    this._visibleColsV1 = _.keyBy(cols, (o: any) => {
      return sentenceToCamelCase(o.colName);
    });
  }

  getVisibleCols(version = 1) {
    if (version === 1) return this._visibleColsV1;
    return this._visibleColsV2;
  }

  flush() {
    this.setVisibleCols([] as any);
    this.setSelectedRowId("");
    this.setConfig(this.getDefaultConfig());
  }

  getCachedClaimRecords(
    insuranceClaimRecords: InsuranceClaimRecord[]
  ): CachedClaimRecord {
    const cachedClaimRecords: CachedClaimRecord = {};
    insuranceClaimRecords?.forEach((claim) => {
      cachedClaimRecords[claim.id] = {
        canvasType: this.isOrthoClaim(claim?.procedure)
          ? CanvasTypes.ORTHO_CANVAS
          : CanvasTypes.DEFAULT_CANVAS,
      } as ClaimDetails;
    });
    return cachedClaimRecords;
  }

  isOrthoClaim(procedureParam: string | string[] | undefined) {
    if (!procedureParam) return false;

    let procedures: string[];

    if (Array.isArray(procedureParam)) {
      procedures = procedureParam;
    } else {
      procedures = procedureParam.split(",");
    }

    const orthoCodes =
      userService.getClientConfig()?.procedureCodeConfig?.ortho || [];
    return procedures.some((code) => orthoCodes.includes(code)) || false;
  }

  getCanvasPath(pathname: string, claimId: string) {
    const canvasType =
      this.getClaimPageIds()?.cachedClaimRecords?.[claimId]?.canvasType;
    return canvasType ? pathname.replace(/canvas|ortho/, canvasType) : pathname;
  }

  setClaimPageIds(ids: any) {
    this._cacheService.setItemSync(CacheKeys.ClaimPageIds, ids);
  }

  getClaimPageIds(): ClaimIDsCache {
    return this._cacheService.getItemsSync(CacheKeys.ClaimPageIds);
  }

  getClaimId(
    actionNext: boolean,
    currentClaimId: string | null,
    indexId?: number,
    skip?: boolean
  ) {
    const ids = this.getClaimPageIds()?.ids || [];
    let claimIndex = -1;
    if (!skip && indexId !== undefined) {
      if (actionNext) {
        claimIndex = indexId - 1; // point to the next claim
      } else if (indexId >= 0) {
        if (indexId === 0) {
          claimIndex = 0; // point to the first claim
        } else {
          claimIndex = indexId + 1; // point to the prev claim
        }
      }
    } else if (currentClaimId) {
      claimIndex = ids.indexOf(currentClaimId); // point to the current claim
    }

    if (claimIndex !== -1) {
      return actionNext
        ? ids[claimIndex + 1]
        : ids[claimIndex === 0 ? 0 : claimIndex - 1];
    }
    return "";
  }

  /**
   * Get the total claims based on the action and search params
   */
  getTotalClaims(
    searchParamsTotalClaims: string | null,
    stay: boolean,
    claimId: string | null,
    claimPageInfo: ClaimIDsCache,
    currentClaimId: string | null
  ): number {
    if (stay && claimId) {
      const totalClaims =
        claimPageInfo?.totalElements > 1 ? claimPageInfo?.totalElements - 1 : 0;
      claimsTableService.setClaimPageIds({
        ...claimPageInfo,
        ids: (claimPageInfo?.ids || []).filter(
          (id: string) => id !== currentClaimId
        ),
        cachedClaimRecords: Object.fromEntries(
          Object.entries(claimPageInfo?.cachedClaimRecords ?? {}).filter(
            ([id, claimDetails]) => id !== currentClaimId
          )
        ),
        totalElements: totalClaims,
      });
      return totalClaims;
    }
    return searchParamsTotalClaims !== undefined
      ? Number(searchParamsTotalClaims)
      : 0;
  }

  /**
   * Get the table config based on the version of the page
   */
  getClaimsTableConfigByVersion(appVersion: number, isQueuePage: boolean) {
    return isQueuePage
      ? userService.getQueueTableConfigByVersion(appVersion) ??
          this.getResetDefaultConfig()
      : userService.getClaimsTableConfigByVersion(appVersion) ??
          claimsTableService.getResetDefaultConfig(appVersion);
  }

  /**
   * Fetch the next/prev claim from the API based on the action
   */
  async fetchClaimFromApi(
    pathname: string,
    isQueuePage: boolean,
    actionNext: boolean,
    claimPageInfo: ClaimIDsCache,
    claimIndexAfterClick: number,
    totalClaims: number
  ) {
    const appVersion = getPageVersionFromPath(pathname);
    const queries = queryService.getQueriesByVersion(appVersion);
    const fetchQuery: string | undefined = isQueuePage
      ? queries.queuePage?.QUEUE_CLAIMS_QUERY
      : queries.homePage?.CLAIM_SERVICE_QUERY;
    const rootQueryElement = isQueuePage
      ? "claimLevelQueueClaims"
      : "claimLevelHomePageClaims";
    const claimConfig = this.getClaimsTableConfigByVersion(
      appVersion,
      isQueuePage
    );
    const pageSize: number =
      claimPageInfo?.size || claimConfig.default.pageSize;
    const pageOffset = Math.floor(claimIndexAfterClick / pageSize);

    let newClaimId: string = "";
    let newTotalClaims = totalClaims;
    claimConfig.page.offset = pageOffset;
    claimConfig.page.size = pageSize;
    try {
      if (!fetchQuery) {
        throw new Error("No query found for page");
      }

      interface _ClaimFetchResultContent {
        content?: InsuranceClaimRecord[];
        totalElements?: number;
      }

      interface _ClaimFetchResult {
        claimLevelQueueClaims?: _ClaimFetchResultContent;
        claimLevelHomePageClaims?: _ClaimFetchResultContent;
      }

      const claimFetched = await client.query<_ClaimFetchResult>({
        query: gql(fetchQuery),
        variables: claimAnalyticsNormalizer(claimConfig, isQueuePage),
        fetchPolicy: "network-only",
      });

      if (!claimFetched) {
        throw new Error("No data fetched");
      }

      const claimData = claimFetched.data[rootQueryElement];
      if (claimData?.content && claimData?.content?.length > 0) {
        newClaimId = getClaimLineItem(
          claimData.content[claimIndexAfterClick % pageSize]
        )?.id;
        newTotalClaims = claimData?.totalElements ?? newTotalClaims;

        const newClaimIds: string[] = claimData.content.map(
          (claim) => getClaimLineItem(claim)?.id
        );

        const cachedClaimRecords = claimsTableService.getCachedClaimRecords(
          claimData.content
        );

        const existingIds = actionNext
          ? (claimPageInfo?.ids?.slice(-pageSize) || []).reverse()
          : claimPageInfo?.ids?.slice(0, pageSize) || [];

        for (let i = 0; i < existingIds.length; i++) {
          const existingId = existingIds[i];
          if (!newClaimIds.includes(existingId)) {
            actionNext
              ? newClaimIds.unshift(existingId)
              : newClaimIds.push(existingId);
          }
        }

        this.setClaimPageIds({
          ...claimPageInfo,
          ids: newClaimIds,
          cachedClaimRecords,
          pageOffset,
          size: pageSize,
          totalElements: totalClaims,
        });
      }
    } catch (error) {
      console.error(error);
      await refreshMyProfile();
    }
    return {
      newClaimId,
      newTotalClaims,
    };
  }
}

const claimsTableService = new ClaimsTableService(cacheService);
export default claimsTableService;

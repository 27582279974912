import { notification } from "antd";
import "firebase/auth";
import moment from "moment";
import { _ } from "src/shared/libs";
import authService, { AuthService } from "src/services/auth.service";
import cacheService, { CacheService } from "src/services/cache.service";
import userService from "./user.service";
import { CacheKeys } from "src/constants/cache-keys";
import { SessionConfig } from "src/configs/components-config";

export class IntractionService {
  private state: any = {
    lastBlur: undefined,
    lastEnter: undefined,
    lastUnload: undefined,
    inactive: false,
  };
  private setState(state: any) {
    this.state = { ...this.state, ...state };
  }
  constructor(
    private _authService: InstanceType<typeof AuthService>,
    private _cacheService: InstanceType<typeof CacheService>
  ) {
    this.initializeService();
  }

  private initializeService() {
    const lastUnload = this._cacheService.getItemsSync(CacheKeys.LastUnload);
    if (lastUnload) {
      const clientConfig = userService.getClientConfig();
      let inActiveDiff = moment(Date.now()).diff(
        moment(lastUnload),
        "milliseconds"
      );
      if (
        inActiveDiff >
        (Number(clientConfig?.inActivityLogoutPeriod) ||
          Number(process.env.REACT_APP_IDLE_TIME))
      ) {
        this.doLogout();
      }
      this.setState({ lastUnload });
      this._cacheService.removeItem(CacheKeys.LastUnload);
    }
    this.observeActivity();
    this.observeInteraction();
  }

  public get inactive() {
    return this.state.inactive;
  }

  private doLogout() {
    authService.saveUserInfo().then(async (backup) => {
      authService.signOut().finally(() => {
        cacheService.setItemSync("logoutBackup", backup);
        notification.error({
          description: "You must login to proceed with application",
          message: "Session Timeout",
        });
        setTimeout(() => {
          window.location.href = window.location.origin;
        }, 500);
      });
    });
  }

  private checkDifferenceAndUpdate(pointInTime: any) {
    const difference = moment(Date.now()).diff(moment(pointInTime), "minutes");
    if (difference >= SessionConfig.sessionPeriod / 60)
      this.setState({ inactive: true });
  }

  private observeInteraction() {
    const onclick = () => {
      const auth = this._authService.getAuth();
      const expirationTime = auth?.expiringAt;
      const difference = moment(expirationTime).diff(
        moment(Date.now()),
        "minutes"
      );
      if (difference <= SessionConfig.refreshPeriod / 60 && !this.inactive) {
        this._authService.refreshToken();
      }
    };
    const throttled = _.throttle(onclick, 5 * 1000, { leading: false });
    window.onclick = throttled;
  }

  private observeActivity() {
    // * When user switches to someother tab
    window.onblur = () => {
      this.setState({ lastBlur: Date.now() });
    };

    // * When user switches back to overjet app
    window.onfocus = () => {
      this.checkDifferenceAndUpdate(this.state.lastBlur);
      this.setState({ lastEnter: Date.now() });
    };

    // * When user closes the tab
    window.onbeforeunload = () => {
      this._cacheService.setItemSync(
        CacheKeys.LastUnload,
        Date.now().toString()
      );
    };

    // * When user open the tab or loads the app
    window.onload = () => {
      const lastUnload = this.state.lastUnload;
      this.checkDifferenceAndUpdate(lastUnload);
    };
  }
}

const interactionService = new IntractionService(authService, cacheService);
export default interactionService;
